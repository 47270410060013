.gray-th {
    text-align: center;
    border: solid 1px #ffffff !important;
    color: #ffffff;
}

.normal-th {
    text-align: center;
    color: #ffffff;
    border: solid 1px #ffffff !important;
}

.chance-used-tr {
    border: solid 5px #ffff00;
}
.normal-tr {
    border: solid 5px #ffffff;
}